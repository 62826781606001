<template>
    <el-select
        :class="{ 'basic-select-reverse': dropDownVisible, 'basic-select-for-lazy': true }"
        :value="value"
        @input="$emit('input', $event)"
        :value-key="valueKey"
        :loading="loading"
        clearable
        filterable
        remote
        collapse-tags
        reserve-keyword
        :remote-method="remoteMethod"
        :multiple="multiple"
        no-data-text="无匹配选项"
        @focus="onFocus"
        @change="change"
        v-loadmore:[basicClass]="loadMore"
        :popper-class="basicClass"
        @visible-change="visibleChange"
        v-bind="$attrs"
    >
        <el-option
            class="basic-select-for-search-option"
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >
            <span style="float: left">{{ item.label }}</span>
            <span v-show="showValue" class="option-right">{{ item.value }}</span>
        </el-option>
        <div class="basic-select-loading-info">
            <div v-show="lazyLoaing && !isNoMore" class="load-status">
                <i class="el-icon-loading" style="font-size: 16px"></i>
                <span style="font-size: 12px">&nbsp;加载中...</span>
            </div>
            <div v-show="isNoMore" class="load-status">
                <span style="font-size: 12px">&nbsp;没有更多</span>
            </div>
            <i class="load-status el-icon-more" v-show="!lazyLoaing && !isNoMore"></i>
        </div>
    </el-select>
</template>

<script>
const scrollButtomCallback = function (binding, SELECTDOM) {
    return () => {
        const condition = SELECTDOM.scrollHeight - SELECTDOM.scrollTop === SELECTDOM.clientHeight
        // 判断滚动到底部
        if (condition) {
            // 执行回调方法
            binding.value()
        }
    }
}
const uuid = () => {
    const s = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    s[8] = s[13] = s[18] = s[23] = '-'

    const uuid = s.join('')
    return uuid
}
let scrollCallback = null
import request from '@/libs/http'
export default {
    name: 'basicSelector',
    directives: {
        loadmore: {
            // 指令的定义
            bind(el, binding, { context }) {
                context.$nextTick(() => {
                    const SELECTDOM = document.querySelector(`.${binding.arg} .el-select-dropdown__wrap`)
                    // 为对应的ul绑定滚动条滚动事件
                    scrollCallback = scrollButtomCallback(binding, SELECTDOM)
                    SELECTDOM.addEventListener('scroll', scrollButtomCallback(binding, SELECTDOM))
                })
            },
            unbind(el, binding) {
                const SELECTDOM = document.querySelector(`.${binding.arg} .el-select-dropdown__wrap`)
                SELECTDOM && SELECTDOM.removeEventListener('scroll', scrollCallback)
            }
        }
    },
    emits: ['input', 'change'],
    props: {
        value: {
            type: Array | String | Object | Number,
            required: true
            // defaule: () => []
        },
        url: {
            type: String,
            default: ''
        },
        params: {
            type: Object,
            default: () => ({})
        },
        valueKey: {
            type: String,
            default: ''
        },
        labelKey: {
            type: String,
            default: ''
        },
        searchKey: {
            type: String,
            default: ''
        },
        delay: {
            type: Number,
            default: 200
        },
        multiple: {
            type: Boolean,
            default: false
        },
        showValue: {
            type: Boolean,
            default: false
        },
        isNoPage: {
            type: Boolean,
            default: false
        }
        // modelValue: {
        //     type: Array,
        //     default: () => {
        //         return []
        //     }
        // }
    },
    data() {
        return {
            loading: false,
            page: 1,
            pageSize: 30,
            // value: [],
            options: [],
            selectorConfig: {
                valueKey: '',
                valueDesKey: '',
                labelKey: '',
                labelDesKey: ''
            },
            checkedItem: [],
            dropDownVisible: false,
            basicClass: `basic-class-${uuid()}`,
            keyword: '',
            isNoMore: false,
            lazyLoaing: false,
            timer: ''
        }
    },
    watch: {
        // value: {
        //     handler() {
        //         this.checkedItem = []
        //         if (this.multiple) {
        //             // this.value = []
        //             this.value.forEach((item) => {
        //                 // this.value.push(item[this.selectorConfig.valueDesKey])
        //                 const find = this.options.find((optionsItem) => {
        //                     return optionsItem.value === item[this.selectorConfig.valueDesKey]
        //                 })
        //                 const option = {
        //                     value: item[this.selectorConfig.valueDesKey],
        //                     label: item[this.selectorConfig.labelDesKey]
        //                 }
        //                 if (!find) {
        //                     this.options.push(option)
        //                 }
        //                 this.checkedItem.push(option)
        //             })
        //         } else {
        //             if (this.value.length > 0) {
        //                 // 单选取第一个值，其他忽略
        //                 const valueItem = this.value[0]
        //                 this.value = valueItem[this.selectorConfig.valueDesKey]
        //                 const find = this.options.find((optionsItem) => {
        //                     return optionsItem.value === valueItem[this.selectorConfig.valueDesKey]
        //                 })
        //                 const option = {
        //                     value: valueItem[this.selectorConfig.valueDesKey],
        //                     label: valueItem[this.selectorConfig.labelDesKey]
        //                 }
        //                 if (!find) {
        //                     this.options.push(option)
        //                 }
        //                 this.checkedItem.push(option)
        //             }
        //             // else {
        //             //     this.value = ''
        //             // }
        //         }
        //     },
        //     immediate: true
        // }
    },
    created() {
        this.initKeyMap()
    },
    methods: {
        initKeyMap() {
            const valueKeyArr = this.valueKey.split('#')
            this.selectorConfig.valueKey = valueKeyArr[0]
            this.selectorConfig.valueDesKey = valueKeyArr[1] ? valueKeyArr[1] : valueKeyArr[0]

            const labelKeyArr = this.labelKey.split('#')
            this.selectorConfig.labelKey = labelKeyArr[0]
            this.selectorConfig.labelDesKey = labelKeyArr[1] ? labelKeyArr[1] : labelKeyArr[0]
        },
        visibleChange(visible) {
            this.dropDownVisible = !!visible
            if (visible) {
                this.remoteMethod('', false)
            } else {
                document.querySelector(`.${this.basicClass} .el-select-dropdown__wrap`).scrollTop = 0
                this.page = 1
                this.options = []
                this.isNoMore = false
            }
        },
        change(data) {
            const values = Array.isArray(data) ? data : [data]
            const cbData = []
            this.options.forEach((item) => {
                if (values.includes(item.value)) {
                    const one = {}
                    one[this.selectorConfig.valueDesKey] = item.value
                    one[this.selectorConfig.labelDesKey] = item.label
                    cbData.push(one)
                }
            })
            // this.$emit('input', cbData)
            this.$emit('change', cbData)
        },
        loadMore() {
            this.remoteMethod(this.keyword, true)
        },
        getData(value) {
            return request.post(this.url, {
                [this.searchKey || this.selectorConfig.labelKey]: value,
                ...this.params,
                page: this.page,
                pageSize: this.isNoPage ? 999 : this.pageSize
            })
        },
        formatOptions(list, lazy) {
            const values = []
            this.checkedItem.forEach((checkedItem) => {
                values.push(checkedItem.value)
            })
            const data = []
            list.forEach((listItem) => {
                if (!values.includes(listItem[this.selectorConfig.valueKey])) {
                    data.push({
                        value: listItem[this.selectorConfig.valueKey],
                        label: listItem[this.selectorConfig.labelKey]
                    })
                }
            })
            if (lazy) {
                this.options = Object.freeze([...this.options, ...data])
            } else {
                this.checkedItem.forEach((checkedItem) => {
                    data.unshift(checkedItem)
                })
                this.options = Object.freeze(data)
            }
        },
        remoteMethod(value, lazy = false) {
            if (value !== this.keyword || !lazy) {
                this.page = 1
                this.isNoMore = false
            }
            this.keyword = value
            if (this.isNoMore) {
                return
            }
            if (lazy && !this.lazyLoaing) {
                this.page = this.page + 1
            }
            if (!lazy) {
                this.loading = true
            } else {
                this.lazyLoaing = true
            }
            // 如果是初始加载，则不使用延迟调接口
            let timeout = 300
            if (!value && !lazy) {
                timeout = 0
            } else {
                timeout = this.delay || 300
            }
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.getData(value)
                    .then((res) => {
                        if (this.isNoPage) {
                            this.isNoMore = true
                            this.formatOptions(res, false)
                        } else {
                            if (res && res.items) {
                                // 如果返回list长度为0 ，则讲懒加载设置为没有更多，下次滚动不执行接口调用
                                if (res.items.length <= 0) {
                                    this.isNoMore = true
                                }
                                this.formatOptions(res.items, lazy)
                            } else {
                                this.options = []
                            }
                        }
                    })
                    .finally(() => {
                        this.lazyLoaing = false
                        this.loading = false
                    })
            }, timeout)
        },
        onFocus() {
            if (!this.options.length) {
                this.remoteMethod('', false, 0)
            }
        }
    }
}
</script>
<style lang="scss">
.basic-select-for-lazy {
    width: 100%;
}
.basic-select-loading-info {
    text-align: center;
    padding-bottom: 40px;
    font-size: 14px;
    color: #bbb;
    .load-status {
        height: 30px;
        line-height: 40px;
    }
}
.basic-select-for-search-option {
    .option-right {
        float: right;
        color: #8492a6;
        font-size: 12px;
        margin-left: 4px;
    }
}
</style>
