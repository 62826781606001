<template>
    <el-dialog
        title="选择货号"
        :visible.sync="storeDialogShow"
        width="1200px"
        custom-class="store-dialog"
        :before-close="handleClose"
        append-to-body
        :close-on-press-escape="false"
    >
        <div class="store-dialog-content">
            <div class="item item-form">
                <div class="item-header">筛选条件</div>
                <el-form class="form-box" label-width="100px" size="mini">
                    <el-form-item label="货品名称">
                        <el-input
                            v-model="form.goodsNameList"
                            clearable
                            @keydown.native.enter="searchList"
                            placeholder="请填写货品名称,多个以空格分隔"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="货号">
                        <el-input
                            v-model="form.goodsNoList"
                            clearable
                            @keydown.native.enter="searchList"
                            placeholder="请填写货号,多个以空格分隔"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="年份">
                        <basic-selector
                            v-model="form.yearIdList"
                            :multiple="true"
                            valueKey="id"
                            labelKey="name"
                            searchKey="title"
                            :isNoPage="true"
                            url="/erp2/erp-marketing-unit-spu-price-relation/query-goods-year"
                        ></basic-selector>
                    </el-form-item>

                    <el-form-item label="季节">
                        <el-select v-model="form.quarterIdList" filterable multiple collapse-tags clearable>
                            <el-option
                                v-for="item in quarterOptions"
                                :key="item.name"
                                :label="item.name"
                                :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="款式分类">
                        <el-cascader
                            :options="categoryList"
                            :show-all-levels="true"
                            v-model="form.typeIdList"
                            filterable
                            collapse-tags
                            clearable
                            :props="{
                                label: 'categoryName',
                                value: 'id',
                                children: 'child',
                                emitPath: false,
                                multiple: true
                            }"
                            style="width: 100%"
                            ref="category"
                        ></el-cascader>
                    </el-form-item>

                    <el-form-item label="风格分类">
                        <el-cascader
                            :options="styleList"
                            :show-all-levels="true"
                            v-model="form.styleIdList"
                            filterable
                            collapse-tags
                            clearable
                            :props="{
                                label: 'categoryName',
                                value: 'id',
                                children: 'child',
                                emitPath: false,
                                multiple: true
                            }"
                            style="width: 100%"
                            ref="styleCategory"
                        ></el-cascader>
                    </el-form-item>
                    <!-- <template v-if="isConsole">
                        <el-form-item label="供应商">
                            <select-supplier
                                v-model="form.supplierIdList"
                                placeholder="请选择供应商"
                                :supplierType="supplierType"
                                multiple
                                collapse-tags
                            ></select-supplier>
                        </el-form-item> 
                    </template> -->
                    <el-form-item class="form-footer">
                        <el-button @click="resetForm">重置</el-button>
                        <el-button type="primary" @click="searchList">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="item item-list">
                <div class="item-header">
                    未选择货号<span class="item-header-num">共（{{ total || 0 }}）</span>
                </div>
                <el-table
                    ref="tableIns"
                    :data="list"
                    border
                    class="table-box"
                    @selection-change="listSelectionChange"
                    height="520px"
                    v-loading="loading"
                    @row-dblclick="(row) => rowDblclick(row, 'add')"
                    @row-click="(row, col, event) => rowClick(row, col, event, 'tableIns')"
                >
                    <el-table-column align="center" type="selection" width="48"> </el-table-column>
                    <el-table-column align="center" prop="code" label="货号" width="155" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="label"
                        label="货品名称"
                        width="155"
                        :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                </el-table>
                <el-pagination
                    small
                    background
                    @current-change="handleCurrentChange"
                    @size-change="handleSizChange"
                    :current-page="page"
                    :page-size="pageSize"
                    :pager-count="5"
                    :page-sizes="[100, 200, 500, 1000]"
                    layout="sizes,prev,pager,next"
                    :total="total"
                >
                </el-pagination>
            </div>
            <div class="item item-btns">
                <el-button type="info" size="small" plain class="btn-item" @click="checkAll">
                    <svg-icon class-name="size-icon" icon-class="shift-right" />
                    <span>全选</span>
                </el-button>
                <el-button type="info" size="small" plain class="btn-item" @click="check">
                    <svg-icon class-name="size-icon" icon-class="shift-right" />
                    <span>右移</span>
                </el-button>
                <el-button type="info" size="small" plain class="btn-item" @click="remove">
                    <svg-icon class-name="size-icon" icon-class="shift-left" />
                    <span>左移</span>
                </el-button>
                <el-button type="info" size="small" plain class="btn-item" @click="removeAll">
                    <svg-icon class-name="size-icon" icon-class="shift-left" />
                    <span>全选</span>
                </el-button>
            </div>
            <div class="item item-checks">
                <div class="item-header">
                    已添加货号<span class="item-header-num">共（{{ checkList.length || 0 }}）</span>
                </div>
                <el-table
                    ref="checkTableIns"
                    :data="checkList"
                    border
                    center
                    class="table-box"
                    @selection-change="checkListSelectionChange"
                    height="548px"
                    @row-dblclick="(row) => rowDblclick(row, 'del')"
                    @row-click="(row, col, event) => rowClick(row, col, event, 'checkTableIns')"
                >
                    <el-table-column align="center" type="selection" width="48"> </el-table-column>
                    <el-table-column align="center" prop="code" label="货号" width="155" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="label"
                        label="货品名称"
                        width="155"
                        :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleClose">取 消</el-button>
            <el-button size="small" type="primary" @click="confirm">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
// import selectSupplier from '@/components/selectSupplier/index'
import basicSelector from '@/components/basicSelector/index'
import { queryAllByType } from '@/libs/http/api.js'
import { erp2QueryGoodsInfo, queryGoodsQuarter } from '@/libs/http/modules/erp'
import svgIcon from '@/components/SvgIcon/index'
const ID_KEY = 'spuInfoId'
const NAME_KEY = 'goodsNo'
const CODE_KEY = 'goodsNo'
export default {
    name: 'storeDialog',
    emits: ['confirm'],
    inject: ['supplierType', 'isConsole', 'multiple', 'modelKey'],
    components: {
        // selectSupplier,
        basicSelector,
        svgIcon
    },
    data() {
        return {
            storeDialogShow: false,
            loading: false,
            form: {
                goodsNameList: '',
                goodsNoList: '',
                yearIdList: [],
                quarterIdList: [],
                typeIdList: [],
                styleIdList: [],
                supplierIdList: []
            },
            list: [],
            selectList: [],

            checkList: [],
            selectCheckList: [],
            total: 0,
            page: 1,
            pageSize: 100,

            categoryList: [], //款式
            styleList: [], //风格
            quarterOptions: [] //季度
        }
    },
    created() {
        this.getCategoryOrStyle(0)
        this.getCategoryOrStyle(1)
        this.getQuarterOptions()
    },
    methods: {
        //打开
        show(data) {
            if (data && data.length) {
                this.checkList = data
            }
            this.storeDialogShow = true
        },
        //关闭
        handleClose() {
            this.storeDialogShow = false
            this.resetForm()
            this.list = []
            this.selectList = []
            this.selectCheckList = []
            this.checkList = []
            this.total = 0
            this.page = 1
            this.pageSize = 100
        },
        // 确认
        confirm() {
            if (!this.multiple && this.checkList.length > 1) {
                this.$message.error('单选模式,仅能选择一条数据')
                return
            }
            if (!this.checkList.length) {
                this.$message.error('请先选择数据')
                return
            }
            this.$emit('confirm', this.checkList)
            this.handleClose()
        },
        resetForm() {
            this.form = {
                goodsNameList: '',
                goodsNoList: '',
                yearIdList: [],
                quarterIdList: [],
                typeIdList: [],
                styleIdList: [],
                supplierIdList: []
            }
        },
        searchList() {
            let reqData = {
                page: this.page,
                pageSize: this.pageSize
            }
            Object.keys(this.form).forEach((key) => {
                if (Object.prototype.toString.call(this.form[key]) === '[object Array]' && this.form[key].length) {
                    if (key === 'quarterIdList') {
                        reqData[key] = this.quarterOptions
                            .filter((item) => this.form[key].includes(item.name))
                            .map((item) => item.idList)
                            .flat()
                    } else {
                        reqData[key] = this.form[key]
                    }
                }
                if (Object.prototype.toString.call(this.form[key]) === '[object String]' && this.form[key].length) {
                    if (['goodsNameList', 'goodsNoList'].includes(key)) {
                        reqData[key] = this.form[key].split(' ')
                    } else {
                        reqData[key] = this.form[key]
                    }
                }
            })
            this.loading = true
            erp2QueryGoodsInfo(reqData)
                .then((data) => {
                    this.list =
                        data.items.map((i) => {
                            return {
                                id: this.modelKey ? i[this.modelKey] : i[ID_KEY],
                                label: i.goodsName,
                                name: i[NAME_KEY],
                                code: i[CODE_KEY],
                                protoData: i
                            }
                        }) || []
                    this.total = +data.totalItem || 0
                })
                .catch((error) => {
                    this.$message.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        //未选择列复选回调
        listSelectionChange(data) {
            this.selectList = data
        },
        //已选择列复选回调
        checkListSelectionChange(data) {
            this.selectCheckList = data
        },
        //全选
        checkAll() {
            this.checkList = this.uniqueFunc([...this.checkList, ...this.list], 'id')
            this.list = []
        },
        //选中
        check() {
            this.checkList = this.uniqueFunc([...this.checkList, ...this.selectList], 'id')
            this.list = this.list.filter((item) => !this.checkList.map((i) => i.id).includes(item.id))
            this.selectList = []
        },
        //取消选中
        remove() {
            this.list = this.uniqueFunc([...this.list, ...this.selectCheckList], 'id')
            this.checkList = this.checkList.filter((item) => !this.selectCheckList.map((i) => i.id).includes(item.id))
            this.selectCheckList = []
        },
        //取消全选
        removeAll() {
            this.list = this.uniqueFunc([...this.list, ...this.checkList], 'id')
            this.checkList = []
        },
        //行双击
        rowDblclick(row, type) {
            if (type === 'add') {
                this.checkList = this.uniqueFunc([...this.checkList, row], 'id')
                this.list = this.list.filter((item) => row.id !== item.id)
            } else if (type === 'del') {
                this.list = this.uniqueFunc([...this.list, row], 'id')
                this.checkList = this.checkList.filter((item) => row.id !== item.id)
            }
        },
        //行单击
        rowClick(row, col, event, ref) {
            row.flag = !row.flag
            this.$refs[ref].toggleRowSelection(row, row.flag)
        },

        // 去重
        uniqueFunc(arr, uniId) {
            const res = new Map()
            return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1))
        },

        handleCurrentChange(e) {
            this.page = e
            this.searchList()
        },
        handleSizChange(e) {
            this.page = 1
            this.pageSize = e
            this.searchList()
        },

        //查询 款式/风格
        getCategoryOrStyle(type) {
            queryAllByType({ type })
                .then((data) => {
                    if (type == 0) {
                        this.categoryList = data || []
                    }
                    if (type == 1) {
                        this.styleList = data || []
                    }
                })
                .catch((error) => {
                    this.$message.error(error)
                })
        },
        getQuarterOptions(value = '') {
            queryGoodsQuarter({
                title: value
            })
                .then((data) => {
                    this.quarterOptions = data || []
                })
                .catch((error) => {
                    this.$message.error(error)
                })
        }
        // setSession(key, data) {
        //     sessionStorage.setItem(key, JSON.stringify(data))
        // },
        // getSession(key) {
        //     return JSON.parse(sessionStorage.getItem(key))
        // }
    }
}
</script>

<style lang="scss">
.store-dialog {
    .el-dialog__header {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-dialog__headerbtn {
            position: inherit;
        }
        border-bottom: 1px solid #eee;
    }
    .el-dialog__body {
        padding: 10px;
        border-bottom: 1px solid #eee;
    }
    .el-dialog__footer {
        padding: 10px 20px;
    }
    .store-dialog-content {
        display: flex;
        align-items: center;
        height: 600px;
        .item {
            padding: 10px;
            display: flex;
            flex-direction: column;
            height: 100%;
            .table-box {
                flex: 1;
                width: 359px;
            }
        }
        .item-header {
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: #909399;
            font-size: 14px;
            font-weight: bold;
            border: 1px solid #dfe6ec;
            border-bottom: none;
            background-color: #f9fafc;
            position: relative;
            .item-header-num {
                position: absolute;
                right: 0;
                top: 0;

                font-size: 12px;
                font-weight: 500;
                vertical-align: baseline;
            }
        }
    }
    .item-form {
        .form-box {
            border: 1px solid #dfe6ec;
            padding: 10px;
            height: 100%;
            width: 302px;
            position: relative;
        }
        .el-form-item {
            margin-bottom: 10px;
        }
        .form-footer {
            position: absolute;
            bottom: 0;
            right: 10px;
            display: flex;
            justify-content: flex-end;
        }
    }
    .el-table .el-table__cell {
        padding: 0 !important;
    }
    .el-table-column--selection .cell {
        padding-right: 10px;
    }
    .el-table__header-wrapper {
        th.el-table__cell {
            background-color: #f9fafc;
        }
    }
    .item-btns {
        justify-content: center;
        align-items: center;
        // width: 100px;
        .btn-item {
            margin-bottom: 10px;
            & > span {
                display: flex;
                align-items: center;
            }
        }
        .el-button + .el-button {
            margin-left: 0;
        }
    }
    .size-icon {
        margin-right: 5px;
        font-size: 16px;
    }
    .item-list {
        .el-pagination {
            display: flex;
            justify-content: flex-end;
            padding: 3px 5px;
            border: 1px solid #dfe6ec;
            border-top: none;
            background-color: #f9fafc;
            position: relative;
            // .el-pagination__total {
            //     flex: 1;
            // }
            .btn-next {
                margin-right: 0;
            }
        }
        .el-pagination__sizes {
            position: absolute;
            top: 0;
            left: 0;
            flex: 1;
            .el-input__inner {
                border: none;
                border-radius: 0;
                background-color: #f9fafc;
            }
            .el-input input {
                // 解决客户端错位问题
                position: relative;
                top: -1px;
            }
        }
    }

    .el-select--mini,
    .el-cascader--mini {
        height: 28px;
        line-height: 28px;
        .el-select__tags,
        .el-cascader__tags {
            flex-wrap: nowrap;
            align-items: center;
            .el-tag {
                max-width: 86px !important;
                position: relative;
            }
        }
        .el-select__tags,
        .el-cascader__tags input {
            min-width: 20px;
        }
        .el-input input {
            height: 28px !important;
        }
        .el-tag + .el-select__search-input {
            margin: 2px;
        }
        .el-tag + .el-cascader__search-input {
            margin: 2px 2px 2px 0;
            padding-left: 15px;
        }
    }
}
</style>
