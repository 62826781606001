<template>
    <div class="erp-selector">
        <el-select
            v-if="slectShow"
            :value="value"
            @input="$emit('input', $event)"
            :multiple="multiple"
            clearable
            collapse-tags
            value-key="id"
            :disabled="disabled"
            v-bind="$attrs"
            size="small"
            popper-class="erp-selector-list"
            @remove-tag="handleRemove"
            @clear="handleClear"
            @focus="erpSelectFocus"
        >
            <el-option v-for="i in options" :key="i.id" :label="i.name" :value="simpled ? i.id : i" disabled>
            </el-option>
        </el-select>
        <!-- inputShow -->
        <el-input  v-if="inputShow"
                   :value="value"
                   @input="$emit('input', $event)"
                   value-key="id"
                   v-bind="$attrs"
                   size="small"
                   clearable
                   @remove-tag="handleRemove"
                   @clear="handleClear"
        ></el-input>
        <slot v-bind="{ openFun: erpSelectFocus }">
            <el-button
                class="erp-selector-btn"
                size="small"
                type="primary"
                icon="el-icon-plus"
                :disabled="disabled"
                @click="erpSelectFocus"
            ></el-button>
        </slot>
        <component :ref="config.ref" :key="config.ref" :is="config.componentName" @confirm="handleConfirm"></component>
    </div>
</template>

<script>
import storeDialog from './storeDialog.vue'
import goodsNoDialog from './goodsNoDialog.vue'
import warehouseDialog from './warehouseDialog.vue'
import { configList } from './config.js'
export default {
    name: 'erpSelector',
    components: {
        storeDialog,
        goodsNoDialog,
        warehouseDialog
    },
    props: {
        //绑定对象
        value: {
            type: Array | String | Object | Number,
            required: true
            // defaule: () => []
        },
        //选择器类型
        type: {
            type: String,
            required: true,
            default: 'store' // store/goodsNo
        },
        //是否显示select
        slectShow: {
            type: Boolean,
            default: true
        },
        //是否显示输入框
        inputShow: {
            type: Boolean,
            default: false
        },
        //是否简单数据
        simpled: {
            type: Boolean,
            default: true
        },
        //供应商类型 //货号-供应商选择器使用
        supplierType: {
            type: String,
            default: ''
        },
        // 是否为控制台
        isConsole: {
            type: Boolean,
            default: true
        },
        //多选
        multiple: {
            type: Boolean,
            default: true
        },
        //需要绑定的key值
        modelKey: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    provide() {
        return { ...this.$props, ...this.$attrs }
    },
    data() {
        return {
            options: []
        }
    },
    watch: {
        //解决外部清空对象,内部选择项未被清除问题
        value(val) {
            if (Object.prototype.toString.call(val) === '[object Array]') {
                if (val.length) {
                    if (!this.simpled) {
                        this.options = val
                    }
                } else {
                    this.options = []
                }
            }
            if (Object.prototype.toString.call(val) === '[object Object]' && !Object.keys(val).length) {
                this.options = []
            }
            if (!val && val !== 0) {
                this.options = []
            }
        }
    },
    computed: {
        config() {
            return configList.find((item) => item.ref === this.type)
        }
    },
    created() {},
    methods: {
        erpSelectFocus() {
            this.$refs[this.config.ref].show(this.options)
        },
        handleConfirm(data) {
            this.options = data
            if(!this.inputShow) {
                 if (this.multiple) {
                //多选
                if (this.simpled) {
                    this.$emit(
                        'input',
                        data.map((i) => i.id)
                    )
                    this.$emit(
                        'change',
                        data.map((i) => i.id),
                        data.map((i) => i.protoData)
                    )
                } else {
                    this.$emit('input', data)
                    this.$emit('change', data)
                }
            } else {
                //单选
                this.$emit('input', data[0].id)
                this.$emit('change', data[0])
            }
            }else {
                if (this.multiple) {
                //多选
                if (this.simpled) {
                    this.$emit(
                        'input',
                        data.map((i) => i.id).join(' ')
                    )
                    this.$emit(
                        'change',
                        data.map((i) => i.id),
                        data.map((i) => i.protoData)
                    )
                } else {
                    this.$emit('input', data)
                    this.$emit('change', data)
                }
            } else {
                //单选
                this.$emit('input', data[0].id)
                this.$emit('change', data[0])
            }
            }
           
        },
        handleRemove(val) {
            this.options = this.options.filter((i) => i.id !== (this.simpled ? val : val.id))
            this.$emit('change', this.value)
        },
        handleClear() {
            this.options = []
            this.$emit('change', this.value)
        }
    }
}
</script>

<style lang="scss">
.erp-selector {
    display: flex;
    width: 100%;

    .el-select--small {
        height: 32px;
        line-height: 32px;
        .el-select__tags {
            flex-wrap: nowrap;
            align-items: center;
            .el-tag {
                max-width: 90px !important;
                position: relative;
            }
        }
        .el-select__tags input {
            min-width: 20px;
        }
        .el-input input {
            height: 32px !important;
            // 解决客户端错位问题
            position: relative;
            top: -1px;
        }
        .el-tag + .el-select__search-input {
            margin: 2px;
        }
    }

    .el-select {
        display: flex;
        width: 100%;
    }
    .el-input__inner {
        border-radius: 4px 0 0 4px;
    }
    .erp-selector-btn {
        background: #c6e7ff;
        color: black;
        border: none;
        border-radius: 0 4px 4px 0;
        height: 32px;
    }
    & > .el-select .el-icon-arrow-up {
        display: none;
    }
}
.erp-selector-list {
    position: relative;
    display: none !important;
    visibility: hidden !important;
    z-index: -2;
}
</style>
