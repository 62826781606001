export const configList = [
    {
        ref: 'store',
        componentName: 'storeDialog'
    },
    {
        ref: 'goodsNo',
        componentName: 'goodsNoDialog'
    },
    {
        ref: 'warehouse',
        componentName: 'warehouseDialog'
    }
]
